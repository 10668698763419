import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  Modal,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import QRCode from 'qrcode.react';
import { useMediaQuery } from '@mui/material';

const PaymentsTable = ({ payments }) => {
  // State for QR Code modal
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedQrUrl, setSelectedQrUrl] = useState('');

  // Sorting logic
  const sortedPayments = [...payments].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // For responsiveness
  const isWide = useMediaQuery('(min-width:1130px)');

  // Open/Close Modal
  const handleOpenQrModal = (url) => {
    setSelectedQrUrl(url);
    setQrModalOpen(true);
  };
  const handleCloseQrModal = () => {
    setQrModalOpen(false);
    setSelectedQrUrl('');
  };

  // Reusable QR code modal
  const qrModal = (
    <Modal open={qrModalOpen} onClose={handleCloseQrModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 440,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography style={{ marginBottom: '20px' }} variant="h6" gutterBottom>
          Scan to Pay
        </Typography>
        {selectedQrUrl && <QRCode value={selectedQrUrl} size={340} />}
      </Box>
    </Modal>
  );

  return (
    <Box
      sx={{ width: '90%', margin: '0 auto' }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#121212',
      }}
    >
      <div style= {{
        padding: "36px 0px",
        
      }}>
        <Typography variant="h5" gutterBottom>
          Payments
        </Typography>
      </div>

      {isWide ? (
        /* -----------------------
           TABLE LAYOUT for >= 1130px
        --------------------------*/
        <TableContainer
          component={Paper}
          sx={{
            color: 'white',
            overflowX: 'auto',
            background: "#121212"
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow >
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Name</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Email</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Amount</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Description</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Status</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Created At</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>Payment Link</TableCell>
                <TableCell sx={{ fontSize: "1.05rem", fontWeight: "100", color: 'white' }}>QR Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPayments.map((payment, index) => (
                <TableRow
                  key={payment.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'grey.800'
                    },
                  }}
                >
                  <TableCell sx={{ color: 'white' }}>
                    {payment.customer_name || payment.name}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {payment.customer_email}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    ${((payment.amount || 0) / 100).toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {payment.description}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {payment.status.charAt(0).toUpperCase() +
                      payment.status.slice(1).toLowerCase()}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {new Date(payment.created_at).toLocaleString(undefined, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {payment.hosted_url ? (
                      <a
                        href={payment.hosted_url}
                        style={{ textDecoration: 'underline', color: 'inherit' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {payment.hosted_url ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenQrModal(payment.hosted_url)}
                      >
                        View QR
                      </Button>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        /* ---------------------------
           CARD LAYOUT for < 1130px
        ----------------------------*/
        <Box sx={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {sortedPayments.map((payment) => (
            <Box
              key={payment.id}
              sx={{
                mb: 2,
                p: 2,
                border: '1px solid gray',
                borderRadius: '8px',
                color: 'white',
                '&:hover': { backgroundColor: 'grey.800' },
              }}
            >
              <Typography variant="body1">
                <strong>Name:</strong> {payment.customer_name || payment.name}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {payment.customer_email}
              </Typography>
              <Typography variant="body1">
                <strong>Amount:</strong> ${((payment.amount || 0) / 100).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                <strong>Description:</strong> {payment.description}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong>{' '}
                {payment.status.charAt(0).toUpperCase() + payment.status.slice(1).toLowerCase()}
              </Typography>
              <Typography variant="body1">
                <strong>Created At:</strong>{' '}
                {new Date(payment.created_at).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Typography>
              <Typography variant="body1">
                <strong>Payment Link:</strong>{' '}
                {payment.hosted_url ? (
                  <a
                    href={payment.hosted_url}
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                ) : (
                  'N/A'
                )}
              </Typography>
              <Box style={{ textAlign: "center" }} sx={{ mt: 1 }}>
                {payment.hosted_url ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenQrModal(payment.hosted_url)}
                  >
                    View QR
                  </Button>
                ) : (
                  'N/A'
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {qrModal}
    </Box>
  );
};

export default PaymentsTable;